import React from 'react'
import {Navbar, Nav, Button, Image, Form, Modal} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nccd from '../images/Nccd_Logo.png';
import './navbar.css';


export default function NavbarComp() {





    return (
        <div>
            <>
                <Navbar className="navbarNew" variant="dark" style={{backgroundColor: 'white'}}>
                    <Navbar.Brand as={Link} to="/dashboard"><Image style={{height: '70px', width:'140px' , backgroundColor: 'white' }} src={Nccd} fluid/></Navbar.Brand>
                     <Nav className="mr-auto ml-auto" inline>
                        <Nav.Link as={Link} to="/dashboard" style={{color: '#253eb2'}} >Lobby</Nav.Link>
                        <Nav.Link as={Link} to="/auditorium" style={{color: '#253eb2'}} >Auditorium</Nav.Link>
                        <Nav.Link as={Link} to="/resource-center" style={{color: '#253eb2'}} >Resource Centre</Nav.Link> 
                        <Nav.Link as={Link} to="/meet-the-expert" style={{color: '#253eb2'}} >Meet the Experts</Nav.Link>
                        {/* <Nav.Link style={{color: '#253eb2'}} href="/buyers-guide">Buyer's Guide</Nav.Link> */}
                        <Nav.Link as={Link} to="/fun-zone" style={{color: '#253eb2'}} >Fun Zone</Nav.Link>
                        <Nav.Link as={Link} to="/social-media" style={{color: '#253eb2'}} >Social Media</Nav.Link>
                        </Nav>
                    <Form inline>
                    <Button as={Link} to="/" style={{color: '#253eb2'}} variant="outline-primary" >Logout</Button>
                    </Form>
                </Navbar>
                {/* <div className="w-100 text-center mt-2">
                <Button variant="link" onClick={handleLogout}>Log Out</Button>
            </div> */}
            </>
        </div>
    )
}
