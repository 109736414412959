import React, {useState, useEffect} from 'react'
import ResourceCenterImg from '../images/Resource_Center_Ginseng.jpg';
import { Image, Modal, Button } from 'react-bootstrap';
import ModalVideo from 'react-modal-video'
import NavbarComp from './Navbar';
import Thumb_YT from '../images/Thumb_YT.jpeg';
import firebase from '../firebase';


export default function ResourceCenter() {

    const [model1, setModel1] = useState(false);
    const [isOpen, setOpen] = useState(true);
    const handleClose = () => setModel1(false);
    const handleShow = () => setModel1(true);
    const [linkY, setLinkY] = useState('');
    const [T1, setT1] = useState('');
    const [T2, setT2] = useState('');



    const ref = firebase.firestore().collection('data')

    function getLink(){
        ref.onSnapshot((querySnapshot) => {
            const items = [];
            console.log(items,'items')
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            })
            setLinkY(items[0].seminarVideo)
            setT1(items[0].trainingVideo1)
            setT2(items[0].trainingVideo2)
        })
    }

    useEffect(()=>{
        getLink()
    },[])




    return (
        <div>
            <NavbarComp/>

        <div style={{position: 'relative'}}>
            <Image src={ResourceCenterImg} fluid/>
            
            {/* First Slide */}
            <a target="_blank" style={{cursor: 'pointer'}} href={T1}>
                <div style={{position: 'absolute', left: '21%', top: '40%', width: '19%', height: '21%'}}>
                </div>
            </a>


            {/* Second Slide */}
            <a target="_blank" style={{cursor: 'pointer'}} href={linkY}>
                <div style={{position: 'absolute', left: '41%', top: '40%', width: '18%', height: '21%'}}>
                </div>
            </a>
            
            {/* Third Slide */}
            <a target="_blank" style={{cursor: 'pointer'}} href={T2}>
                <div style={{position: 'absolute', left: '60%', top: '40%', width: '18%', height: '21%'}}>
                </div>
            </a>
             



        </div>
        </div>

    )
}
